import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import client from "../lib/client";
import { useLocalStorage } from '@uidotdev/usehooks';

function Footer() {
    const [contact, setContact] = useState([]);
    const [footer, setFooter] = useState([]);
    const [language, setLanguage] = useLocalStorage('language');
    
    useEffect(() => {
        // Fetch product data from Sanity
        client.fetch(`*[_type == "contact"]{
    email,
    phone,
    facebook,
    instagram,
    tiktok,
  }`).then((data) => {
            setContact(data[0]);
        }).catch((error) => {
            console.error('Fetch error: ', error);
        });
    }, []);
    useEffect(() => {
        // Fetch product data from Sanity
        client.fetch(`*[_type == "footer" && language == "${language}"]{
          footerHeading,
          footerDescription,
          shopInfo,
          contactus,
          termscondition,
          contact,
          email,
          phone,
          copyright,
          madeby
        }`).then((data) => {
            setFooter(data[0]);
        }).catch((error) => {
            console.error('Fetch error: ', error);
        });
    }, [language]);
    return (
        <>
        <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5">
            <div className="container py-5">
                <div className="pb-4 mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="col-lg-4">
                            <Link to="/">
                                <h1 className="text-primary mb-0">Mrekullia e Aloes</h1>
                            </Link>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex justify-content-md-end pt-3">
                                <Link className="btn btn-outline-secondary me-2 btn-md-square rounded-circle d-flex justify-content-center align-items-center" target='_blank' to={contact.facebook}><i className="bx bxl-facebook"></i></Link>
                                <Link className="btn btn-outline-secondary me-2 btn-md-square rounded-circle d-flex justify-content-center align-items-center" target='_blank' to={contact.instagram}><i className="bx bxl-instagram"></i></Link>
                                <Link className="btn btn-outline-secondary btn-md-square rounded-circle d-flex justify-content-center align-items-center" target='_blank' to={contact.tiktok}><i className='bx bxl-tiktok'></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-md-between">
                    <div className="col-md-4 col-12">
                        <div className="footer-item">
                            <h4 className="text-light mb-3">{footer.footerHeading}</h4>
                            <p className="mb-4">{footer.footerDescription}</p>
                        </div>
                    </div>
                    <div className="col-md-2 col-12">
                        <div className="footer-item d-flex flex-column text-start">
                            <h4 className="text-light mb-3">{footer.shopInfo}</h4>
                            <Link className="btn-link" to="/contact">{footer.contactus}</Link>
                            <Link className="btn-link" to="/terms">{footer.termscondition}</Link>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="footer-item">
                            <h4 className="text-light mb-3">{footer.contact}</h4>
                            <p>{footer.email}: <Link to={`mailto:${contact.email}`}>{contact.email}</Link></p>
                            <p>{footer.phone}: <Link to={`tel:${contact.phone}`}>{contact.phone}</Link></p>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="footer-item">
                            <div className="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="675f8164e34ca87255d5bd88" data-style-height="52px" data-style-width="100%">
                              <a href="https://www.trustpilot.com/review/aloe-vera.mk" target="_blank" rel="noopener">Trustpilot</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid copyright bg-dark py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        <span className="text-light"><Link to="/"><i className="fas fa-copyright text-light me-2"></i>Mrekullia e Aloes</Link>, {footer.copyright}</span>
                    </div>
                    <div className="col-md-6 my-auto text-center text-md-end text-white">
                    {footer.madeby} <i className='bx bxs-heart text-danger'></i> <Link className="border-bottom" to="mailto:asafrushiti3@gmail.com">Asaf Rushiti</Link> 
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer
